<app-navbar-two></app-navbar-two>

<div class="page-title-area"></div>

<section class="contact-box">
  <div class="container">
    <div class="we-do-content-area">
      <h3>Impressum</h3>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-contact-box">
          <i class="fa fa-envelope"></i>

          <div class="content-title">
            <h3>Mailadresse</h3>
            <a href="mailto:hallo@affiliatepartnerag.com">hallo@affiliatepartnerag.com</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-contact-box">
          <i class="fa fa-map-marker"></i>
          <div class="content-title">
            <h3>Firmensitz</h3>
            <p>Affiliate Partner AG<br />Ruessenstrasse 6<br />6340 Baar<br />Schweiz</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
        <div class="single-contact-box">
          <i class="fa fa-phone"></i>

          <div class="content-title">
            <h3>Telefon</h3>
            <a href="tel:+41 77 533 61 11">+41 77 533 61 11</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="contact-area pb-100">
  <div class="container">
    <div class="section-title">
      <h2>Senden Sie uns eine Nachricht</h2>
      <p>Wenn Sie Fragen oder Anregungen haben, nutzen Sie zur Kontaktaufnahme doch einfach dieses
        Kontaktformular.</p>
    </div>

    <div class="contact-form">
      <form action="https://usebasin.com/f/9d979a2162db" name="kontaktformular" method="POST">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="text" name="name" id="name" class="form-control" placeholder="Name" />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <input type="email" name="email" id="email" class="form-control" placeholder="Mailadresse" />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <input type="text" name="phone_number" class="form-control" placeholder="Telefonnummer" />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <input type="text" name="subject" id="subject" class="form-control" placeholder="Betreff" />
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Ihre Nachricht"></textarea>
            </div>
          </div>

          <div class="col-lg-12">

          </div>

          <div class="col-lg-12">
            <input type="hidden" name="_gotcha" />
            <button type="submit" class="default-btn">Absenden</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
