<app-navbar-two></app-navbar-two>

<div class="marketing-home-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="hero-content">
              <h1>Affiliate Partner AG</h1>
              <p>Seit mehr als 20 Jahren sind wir Partner f&uuml;r Affiliate-Systeme.
                Wir bieten kompetente Unterst&uuml;tzung in allen Bereichen des Affiliate-Marketings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
  <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
  <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image" width="15" height="15"></div>
  <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image" width="18" height="18"></div>
  <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image" width="12" height="11"></div>
</div>

<section class="top-services-section pb-100">
  <div class="container">
    <div class="top-services-area">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="top-services-content">
            <img src="assets/img/top-services/top-services1.png" alt="Concept" width="46" height="52">
            <h3>Concept</h3>
            <p>Wir erarbeiten gemeinsam mit Ihnen das Konzept f&uuml;r Ihr neues Projekt.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="top-services-content">
            <img src="assets/img/top-services/top-services2.png" alt="Consulting" width="48" height="46">
            <h3>Consulting</h3>
            <p>Wir beraten und unterst&uuml;tzen Sie kompetent in jeder Phase Ihres Projektes.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="top-services-content">
            <img src="assets/img/top-services/top-services3.png" alt="Network" width="52" height="48">
            <h3>Network</h3>
            <p>Profitieren Sie von unserem gro&szlig;en Netzwerk und unseren langj&auml;hrigen Partnern.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="top-services-content">
            <img src="assets/img/top-services/top-services4.png" alt="Analytics" width="50" height="50">
            <h3>Analytics</h3>
            <p>Auswertung, Optimierung und Feintuning - wir unterst&uuml;tzen Sie bei der Analyse Ihrer
              Daten.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="what-we-do-section pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="we-do-content-area">
          <h3>Wir entwickeln digitale Strategien f&uuml;r Ihre Produkte und Dienstleistungen</h3>
          <p>Affiliate Marketing ist ein komplexes Thema. Aufgrund unserer mehr als 20-j&auml;hrigen Erfahrung
            k&ouml;nnen wir Sie immer kompetent unterst&uuml;tzen. Ob Sie ein neues Projekt planen oder ein
            bestehendes Projekt erweitern oder optimieren m&ouml;chten. Wir sind immer an Ihrer Seite.</p>
          <p>Wir sind eine Full-Service Agentur f&uuml;r das Affiliate Marketing, unterst&uuml;tzt durch unser
            gro&szlig;es Netzwerk und langj&auml;hrigen Partnern.</p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="we-do-image">
          <img src="assets/img/development.png"
            alt="Wir entwickeln digitale Strategien f&uuml;r Ihre Produkte und Dienstleistungen" width="650"
            height="550">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="achieved-section ptb-100">
  <div class="container">
    <div class="productive-title">
      <h3>Wir sind f&uuml;r Sie da</h3>
      <p>Unsere Dienstleistungen sind darauf zugeschnitten, Ihnen die bestm&ouml;gliche Unterst&uuml;tzung zu bieten.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="productive-area-content">
          <div class="icon">
            <i class="flaticon-headphones"></i>
          </div>
          <h3>Kundenbetreuung</h3>
          <p>Wir sind jederzeit f&uuml;r Sie erreichbar und unterst&uuml;tzen Sie in jeder Phase des
            Projektes.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="productive-area-content">
          <div class="icon">
            <i class="flaticon-tag"></i>
          </div>
          <h3>Flexibles Budget</h3>
          <p>Wir richten uns nach Ihrem Budget und bieten Ihnen hierf&uuml;r die besten Services.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="productive-area-content">
          <div class="icon">
            <i class="flaticon-cyber-security"></i>
          </div>
          <h3>Globale Pr&auml;senz</h3>
          <p>Wir haben Erfahrung und Partner in allen relevanten Bereichen des Marketings.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="productive-area-content">
          <div class="icon">
            <i class="flaticon-innovation"></i>
          </div>
          <h3>Innovative Ideen</h3>
          <p>Bei uns bekommen Sie keine 08/15-L&ouml;sungen. Wir legen Wert auf zukunftssichere
            Entwicklungen.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="productive-area-content">
          <div class="icon">
            <i class="flaticon-unlink"></i>
          </div>
          <h3>Planung & Entwicklung</h3>
          <p>Von der Grundidee zum fertigen Projekt. Wir sind in jeder Phase an Ihrer Seite.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="productive-area-content">
          <div class="icon">
            <i class="flaticon-content"></i>
          </div>
          <h3>Marketing</h3>
          <p>Profitieren Sie von unserer Expertise in der Vermarktung von Affiliate Angeboten.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="needs-section ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="needs-area-content">
          <h3>Lassen Sie uns gemeinsam Ihr neuestes Projekt entwickeln</h3>
          <p>Wir begleiten unsere Kunden bei jedem Schritt - von der ersten Idee &uuml;ber das fertige Produkt bis hin
            zur Optimierung.
          </p>
          <p>
            Wir betreuen seit mehr als 20 Jahren verschiedene Affiliate-Systeme und k&ouml;nnen unseren Kunden mit
            unserer langj&auml;hrigen Erfahrung zur Seite stehen.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="needs">
          <img src="assets/img/affiliate-marketing.png" alt="Affiliate Marketing" width="650" height="550">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="needs-section ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="needs-area-content">
          <h3>Der Schl&uuml;ssel zu Ihrem Erfolg</h3>
          <p>
            F&uuml;r die erfolgreiche Umsetzung eines Projektes ben&ouml;tigt man die richtigen Partner. Die Affiliate
            Partner AG
            unterst&uuml;tzt Sie von der Planung bis zur Entwicklung - und nat&uuml;rlich noch danach.
          </p>
          <p>
            Nutzen Sie unser umfangreiches Netzwerk um f&uuml;r jeden Bereich den passenden Kooperationspartner zu
            finden.
            Beratung, Entwicklung, Optimierung und Payment - nur ein paar wichtige Bausteine f&uuml;r Ihr Affiliate
            System.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="needs">
          <img src="assets/img/key-to-success.png" alt="Der Schl&uuml;ssel zu Ihrem Erfolg" width="650" height="550">
        </div>
      </div>

    </div>
  </div>
</section>
