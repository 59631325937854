<section class="error-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="error-content">
          <img src="assets/img/404.jpg" alt="error">
          <h3>Seite nicht gefunden</h3>
          <p>Die von Ihnen aufgerufene Seite existiert nicht, wurde entfernt oder ist vor&uuml;bergehend nicht
            verf&uuml;gbar.</p>
          <a routerLink="/" class="default-btn-one">Zur Startseite</a>
        </div>
      </div>
    </div>
  </div>
</section>
