import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { MarketingHomeComponent } from "./components/pages/marketing-home/marketing-home.component";
import { NavbarTwoComponent } from "./components/layouts/navbar-two/navbar-two.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    MarketingHomeComponent,
    NavbarTwoComponent,
    AboutComponent,
    ErrorComponent,
    ContactComponent,
  ],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
