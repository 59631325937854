<footer class="footer-section">
  <div class="container">
    <div class="copyright-area">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <p><i class="far fa-copyright"></i> {{ footerYear }} Affiliate Partner AG.</p>
        </div>
        <div class="col-lg-6 col-md-6">
          <ul>
            <li><a routerLink="/impressum">Impressum</a></li>
            <li><a routerLink="/datenschutz">Datenschutz</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="go-top">
  <i class="fas fa-chevron-up"></i>
  <i class="fas fa-chevron-up"></i>
</div>
