import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MarketingHomeComponent } from "./components/pages/marketing-home/marketing-home.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ErrorComponent } from "./components/pages/error/error.component";

const routes: Routes = [
  { path: "", component: MarketingHomeComponent },
  { path: "impressum", component: ContactComponent },
  { path: "datenschutz", component: AboutComponent },
  { path: "error", component: ErrorComponent },
  { path: "**", component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
