<app-navbar-two></app-navbar-two>

<div class="page-title-area"></div>

<section class="what-we-do-section ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="we-do-content-area">
          <h3>Datenschutz</h3>
          <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
            EU-Datenschutzgrundverordnung (DSGVO), ist die:</p>
          <h4 style="text-align: center;">Affiliate Partner AG</h4>
          <p>siehe auch im <a routerLink="/impressum">Impressum</a>.</p>

          <h5>Ihre Betroffenenrechte</h5>
          <p>Unter den angegebenen Kontaktdaten im Impressum können Sie jederzeit folgende Rechte ausüben:</p>
          <ul>
            <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
            <li>Berichtigung unrichtiger personenbezogener Daten,</li>
            <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
            <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher
              Pflichten
              noch nicht löschen dürfen,</li>
            <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und Datenübertragbarkeit, sofern
              Sie
              in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen
              haben.
            </li>
          </ul>
          <p>
            Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die
            Zukunft widerrufen.
          </p>
          <p>
            Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde
            wenden.
            Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit
            oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
            Bereich) mit Anschrift finden Sie unter:
            <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
              target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
          </p>

          <h5>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</h5>
          <p>
            Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten
            Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten
            Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
          </p>
          <ul>
            <li>Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
            <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</li>
            <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</li>
            <li>Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur
              Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe
              Ihrer Daten haben.</li>
          </ul>

          <h5>Löschung bzw. Sperrung der Daten</h5>
          <p>
            Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre
            personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke
            erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen
            vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die
            entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder
            gelöscht.
          </p>

          <h5> Erfassung allgemeiner Informationen beim Besuch unserer Website</h5>
          <p>
            Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen
            allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des
            Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und
            ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse
            auf Ihre Person zulassen.
          </p>
          <p>
            Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten
            korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere
            zu folgenden Zwecken verarbeitet:
          </p>
          <ul>
            <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zu weiteren administrativen Zwecken.</li>
          </ul>
          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus
            den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf
            Ihre Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf.
            Auftragsverarbeiter.
          </p>
          <p>
            Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren
            Internetauftritt und die dahinterstehende Technik zu optimieren.
          </p>

          <h5>Cookies</h5>
          <p>
            Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine
            Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch
            erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser,
            Betriebssystem und Ihre Verbindung zum Internet.
          </p>
          <p>
            Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu
            übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation
            erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
          </p>
          <p>
            In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre
            Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
          </p>
          <p>
            Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser
            sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die
            Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte
            verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese
            Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Website
            möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.
          </p>

          <h5>Registrierung auf unserer Webseite</h5>
          <p>
            Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden einige
            personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten wie
            Telefonnummer und E-Mail-Adresse. Sind Sie bei uns registriert, können Sie auf Inhalte und
            Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem
            die Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu ändern oder zu
            löschen. Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von
            uns über Sie gespeicherten personenbezogenen Daten. Gerne berichtigen bzw. löschen wir diese
            auch auf Ihren Wunsch, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur
            Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung
            angegebenen Kontaktdaten.
          </p>

          <h5>Erbringung kostenpflichtiger Leistungen</h5>
          <p>
            Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B.
            Zahlungsangaben, um Ihre Bestellung ausführen zu können. Wir speichern diese Daten in unseren
            Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.
          </p>

          <h5>Kontaktformular</h5>
          <p>
            Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt,
            erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die
            Angabe einer validen E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der
            anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen
            gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
            Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden
            personenbezogene Daten automatisch gelöscht.
          </p>

          <h5>Änderung unserer Datenschutzbestimmungen</h5>
          <p>

            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
            rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
            Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten
            Besuch gilt dann die neue Datenschutzerklärung.
          </p>

          <h5>Fragen bzgl. Datenschutz</h5>
          <p>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail - siehe <a
              routerLink="/impressum">Impressum</a>.
          </p>

        </div>
      </div>
    </div>
  </div>
</section>
