<div class="navbar-area-two">
  <div class="exto-responsive-nav">
    <div class="container">
      <div class="exto-responsive-menu">
        <div class="logo">
          <a routerLink="/">
            <img src="assets/img/logo/logo-2.png" alt="Affiliate Partner AG" width="130" height="40">
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="exto-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img src="assets/img/logo/logo-2.png" alt="Affiliate Partner AG" width="130" height="40">
        </a>
        <div class="collapse navbar-collapse mean-menu">
          <ul class="navbar-nav">
            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a></li>
            <li class="nav-item"><a routerLink="/impressum" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Impressum</a></li>
            <li class="nav-item"><a routerLink="/datenschutz" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Datenschutz</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
